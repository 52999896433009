<template>
  <b-card title="Tedarikçi Bilgileri">
    <b-list-group>
      <list-item
        title="Tedarikçi Tipi"
        :value="dataItem.customer_type"
      />
      <list-item
        title="Firma Adı"
        :value="dataItem.company"
      />
      <list-item
        title="Telefon"
        :value="dataItem.phone"
      />
      <list-item
        title="Telefon 2"
        :value="dataItem.phone2"
      />
      <list-item
        title="Fax"
        :value="dataItem.fax"
      />
      <list-item
        title="E-Posta"
        :value="dataItem.email"
      />
      <list-item
        title="İlk Temas"
        :value="dataItem.meet"
      />
      <list-item
        title="Notlar"
        :value="dataItem.notes"
      />
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup } from 'bootstrap-vue'
import ListItem from '@/views/Admin/Suppliers/View/ListItem.vue'

export default {
  name: 'Supplier',
  components: {
    BCard,
    BListGroup,
    ListItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['suppliers/dataItem']
    },
  },
}
</script>

<style scoped>

</style>
