<template>
  <div>
    <customer-info />
    <related-persons />
    <activities />
    <customer-invoice />
  </div>
</template>
<script>
import {
} from 'bootstrap-vue'
import CustomerInfo from '@/views/Admin/Suppliers/View/CustomerInfo.vue'
import RelatedPersons from '@/views/Admin/Suppliers/View/RelatedPersons.vue'
import CustomerInvoice from '@/views/Admin/Suppliers/View/CustomerInvoice.vue'
import Activities from '@/views/Admin/Suppliers/View/Activities.vue'

export default {
  name: 'View',
  components: {
    Activities,
    CustomerInfo,
    RelatedPersons,
    CustomerInvoice,
  },
  computed: {
    dataItem() {
      return this.$store.getters['suppliers/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('suppliers/getDataItem', this.$route.params.id)
    },
  },
}
</script>
